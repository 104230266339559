import React, { Component } from "react"
import { Helmet } from "react-helmet"
import './style.scss'

class FormPage extends Component {
    render() {
        return (
            <div className="page FormPage">
                <Helmet>
                    <title>Léo LE POGAM | Formations</title>
                    <link rel="canonical" href="https://leo-lepogam.playdeviant.com/formations" />
                </Helmet>
                <div className="main-wrapper">
                    <div className="wrapper">
                        <div className="left-side">
                            <img src="/images/IPI.png" alt="Logo IPI" />
                        </div>
                        <div className="right-side">
                            <h2>2017 - 2019 | Master Chef de projet ingénierie logiciel</h2>
                            <p>Pendant ma formation à l'<a href="https://www.ipi-ecoles.com/" target="_blank" rel="noreferrer">IPI de Toulouse</a>, j'ai étudié des méthodes de gestion de projet, d'équipe et de budget.</p>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="left-side">
                            <img src="/images/IPI.png" alt="Logo IPI" />
                        </div>
                        <div className="right-side">
                            <h2>2016 - 2017 | Licence Concepteur Developpeur Informatique</h2>
                            <p>Pendant ma formation à l'<a href="https://www.ipi-ecoles.com/" target="_blank" rel="noreferrer">IPI de Toulouse</a>, j'ai étudié les langages PHP, HTML, CSS et Javascript ainsi que le Framework <a href="https://angular.io/" target="_blank" rel="noreferrer">Angular</a>.</p>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="left-side">
                            <img src="/images/IUT_Blagnac.png" alt="Logo IUT Blagnac" />
                        </div>
                        <div className="right-side">
                            <h2>2014 - 2016 | DUT Informatique</h2>
                            <p>Pendant ma formation à l'<a href="https://www.iut-blagnac.fr/fr/formations/dut-informatique" target="_blank" rel="noreferrer">IUT Informatique de Toulouse</a>, j'ai étudié les langages C, PYTHON, HTML et CSS. J'ai aussi acquis des connaissance sur le système binaire, le fonctionnement d'un ordinateur et ses composants.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FormPage