import React, { Component } from "react"
import { Link } from 'react-router-dom'
import { Tab } from '../../presentationals'
import './style.scss'

class Header extends Component {
    state = {
        menuIsVisible: false
    }

    toggleVisibilty = () => {
        this.setState(prevState => ({ menuIsVisible: !prevState.menuIsVisible }))
    }



    render() {
        return (
            <nav className="Header">
                <Link to="/" className="title-wrapper">
                    <img className="logo" src="/images/logo64.png" alt="logo" />
                    <div className="title">Léo LE POGAM</div>
                </Link>
                <div className="spacer"></div>
                <div className="menu-burger" onClick={this.toggleVisibilty} style={{ backgroundImage: `url(/images/${this.state.menuIsVisible ? 'cross' : 'menu-burger'}.png)` }} />
                <div className={`tabs-wrapper${this.state.menuIsVisible ? ' visible' : ''}`}>
                    <Tab name="Informations" onClick={this.toggleVisibilty} icon="" path="/" />
                    <Tab name="Expériences" onClick={this.toggleVisibilty} icon="" path="/experiences" />
                    <Tab name="Formations" onClick={this.toggleVisibilty} icon="" path="/formations" />
                    <Tab name="Compétences" onClick={this.toggleVisibilty} icon="" path="/competences" />
                    <Tab name="Réalisations" onClick={this.toggleVisibilty} icon="" path="/realisations" />
                </div>
            </nav>
        )
    }
}

export default Header