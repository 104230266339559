import React, { Component } from "react"
import { Helmet } from "react-helmet"
import './style.scss'

class CompPage extends Component {
    render() {
        return (
            <div className="page CompPage">
                <Helmet>
                    <title>Léo LE POGAM | Compétences</title>
                    <link rel="canonical" href="https://leo-lepogam.playdeviant.com/competences" />
                </Helmet>
                <div className="main-wrapper">
                    <div className="wrapper">
                        <h2>Langages de programmation</h2>
                        <p><b>Très bonne maîtrise:</b><br />JavaScript, TypeScript, HTML, CSS</p>
                        <p><b>Bonne maîtrise:</b><br />PHP, C#, Java</p>
                    </div>
                    <div className="wrapper">
                        <h2>Frameworks et technologies</h2>
                        <p><b>Très bonne maîtrise:</b><br />ReactJS, ReactNative, NodeJS, NestJS, Angular, MongoDB, PostgreSQL, Docker, Jest, Vitest, TestCafe</p>
                        <p><b>Bonne maîtrise:</b><br />.NET, Cordova, Spring Boot, NextJS</p>
                    </div>
                    <div className="wrapper">
                        <h2>Outils</h2>
                        <p><b>Très bonne maîtrise:</b><br />Git, Gitlab, VS Code, Suite Office, Suite Google, Jira, Confluence, Webstorm</p>
                        <p><b>Bonne maîtrise:</b><br />Android Studio, Visual Studio, SonarQube</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CompPage