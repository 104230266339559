import React, { Component } from "react"
import { Link } from 'react-router-dom'
import './style.scss'

class InfoPage extends Component {
    render() {
        return (
            <div className="page InfoPage">
                <div className="main-wrapper">
                    <p><b>Bonjour ! Vous êtes bien sur le site CV de Léo LE POGAM.</b></p>
                    <p>C'est ici que vous pourrez retrouver toutes les informations me concernant, telles que <Link to="/experiences">mes expériences</Link> et <Link to="/formations">formations</Link> ou encore mes <Link to="/realisations">réalisations</Link>. </p>
                    <p>Depuis mes 14 ans, c'est à dire {new Date().getFullYear() - 2010} ans je me suis pris d'une passion pour le web en commencant par faire <a href="//lesmirabilis.fr" target="_blank" rel="noreferrer">le site de location saisonnière de mes grands parents</a> ce qui m'a permis de développer certaines <Link to="/competences">compétences</Link>.</p>
                    <p>Quand je ne développe pas un petit projet sur mon temps libre, je regarde quelques séries et anime sur Netflix, ou encore je joue aux jeux vidéos avec mes amis.</p>
                    <p>Je suis atteinds d'une maladie chronique appellée <a href="https://marih.fr/pathologies/anmie-de-blackfan-diamond/" target="_blank" rel="noreferrer">Anémie de Blackfan Diamond</a>, j'ai juste moins de globule rouges que la moyenne, ce qui m'oblige à faire une transfusion par mois pour une durée d'une demi journée, sinon ça va <span role="img" aria-label="Winking Face">😉</span></p>
                    <p>Email: <a href="mailto: lepogamleo@gmail.com">lepogamleo@gmail.com</a><br />Tél: <a href="tel: 0614592425">06 14 59 24 25</a><br />Linkedin: <a href="https://www.linkedin.com/in/l%C3%A9o-le-pogam-6110a4285/" target="_blank" rel="noreferrer">https://www.linkedin.com/in/l%C3%A9o-le-pogam-6110a4285/</a></p>
                </div>
            </div>
        )
    }
}

export default InfoPage
