import React, { Component } from "react"
import { Helmet } from "react-helmet"
import './style.scss'

class RealPage extends Component {
    render() {
        return (
            <div className="page RealPage">
                <Helmet>
                    <title>Léo LE POGAM | Réalisations</title>
                    <link rel="canonical" href="https://leo-lepogam.playdeviant.com/realisations" />
                </Helmet>
                <div className="main-wrapper">
                    <a href="https://playdeviant.com" rel="noreferrer" target="_blank" className="wrapper">
                        <div className="left-side">
                            <img src="/images/VGC.png" alt="Logo VGC" />
                        </div>
                        <div className="right-side">
                            <h2>2021 | Console de salon virtuelle</h2>
                            <p>Disponible à l'adresse https://playdeviant.com, cette application web est aussi disponible au format Android.</p>
                            <p>Le but étant de permettre aux utilisateurs de synchroniser leurs téléphones au site pour ensuite choisir un jeu disponible et lancer une partie entre amis!</p>
                        </div>
                    </a>
                    <div className="wrapper">
                        <div className="left-side">
                            <img src="/images/logo192.png" alt="Logo Site CV" />
                        </div>
                        <div className="right-side">
                            <h2>2021 | Site CV</h2>
                            <p>Site web permettant de me présenter ainsi que mes compétences.</p>
                            <p>Le code est disponible sur <a href="https://gitlab.com/Slace31/site-cv" target="_blank" rel="noreferrer">gitlab</a>.</p>
                        </div>
                    </div>
                    <a href="https://lesmirabilis.fr" rel="noreferrer" target="_blank" className="wrapper">
                        <div className="left-side">
                            <img src="/images/lesmirabilis.gif" alt="Logo VGC" />
                        </div>
                        <div className="right-side">
                            <h2>2010 - 2012 | Site web de location saisonnière</h2>
                            <p>Disponible à l'adresse https://lesmirabilis.fr.</p>
                            <p>Site développé pour donner de la visibilité à location saisonnière de mes grands parents, possibilité aux client d'ajouter un message sur le livre d'or s'ils le veulent. Back Office simple permettant de gérer le planning et les tarifs.</p>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

export default RealPage
