import React, { Component } from "react"
import { Helmet } from "react-helmet"
import './style.scss'

class ExpPage extends Component {
  render() {
    return (
      <div className="page ExpPage">
        <Helmet>
          <title>Léo LE POGAM | Expériences</title>
          <link rel="canonical" href="https://leo-lepogam.playdeviant.com/experiences" />
        </Helmet>
        <div className="main-wrapper">
          <div className="wrapper">
            <h2>2023 - Mai 2024 | Développeur full stack chez <a href="https://www.cat-amania.com/fr/" target="_blank" rel="noreferrer">Cat-Amania</a></h2>
            <p>Depuis mon arrivé chez Cat-Amania j'ai pu reprendre le développement avec une des mes technologies de coeur c'est à dire <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a>.</p>
            <p>J'ai eu la chance de de devoir démarrer le développement d'une application <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a> de zéro.</p>
            <p>Cette technologie a déjà bien changé depuis mon projet de <a href="https://playdeviant.com/" target="_blank" rel="noreferrer">console de salon virtuelle</a> avec la suppression des Class Components et l'arrivé des Hooks et des Contexts. Ces modifications rendent le code beaucoup plus clair et maintenable, j'ai hâte de tester les performances avec la version 19 qui arrive bientôt et qui amène son propre compilateur !</p>
            <p>Pour la première fois, la politique de test du client était très prononcée. J'ai donc du mettre en place des tests unitaires avec <a href="https://vitest.dev/" target="_blank" rel="noreferrer">Vitest</a>, qui est une librairie de test similaire à <a href="https://jestjs.io/" target="_blank" rel="noreferrer">Jest</a> mais plus rapide en terme d'éxecution et compatible avec le moteur <a href="https://vitejs.dev/" target="_blank" rel="noreferrer">Vite</a>. Je trouve que l'écriture de test avec cette technologie est un peu longue mais très stable. L'écriture du rapport <a href="https://www.sonarsource.com/products/sonarqube/" target="_blank" rel="noreferrer">SonarQube</a> fonctionne parfaitement ce qui permet son intrégration assez facilement via une CI/CD classique.</p>
            <p>J'ai aussi developpé quelques suites de tests end to end avec <a href="https://testcafe.io/" target="_blank" rel="noreferrer">TestCafe</a>, la syntaxe ressemble beaucoup à celle de <a href="https://jestjs.io/" target="_blank" rel="noreferrer">Jest</a> je trouve ce qui rends sa prise en main très rapide. Elle s'est aussi montrée très stable lors de nos exécutions via nos CI/CD.</p>
            <p>J'ai aussi écrit quelques Pipelines <a href="https://www.jenkins.io/" target="_blank" rel="noreferrer">Jenkins</a> pour m'intégrer à l'infrastructure existante du client.</p>
          </div>
          <div className="wrapper">
            <h2>2021 - 2023 | Développeur full stack chez <a href="https://lrtechnologies.fr/fr/" target="_blank" rel="noreferrer">LR Technologies</a></h2>
            <p>Pendant ces deux années j'ai pu approfondir mes compétences en <a href="https://angular.io/" target="_blank" rel="noreferrer">Angular</a> et <a href="https://nodejs.dev/" target="_blank" rel="noreferrer">NodeJS</a></p>
            <p>J'ai aussi pu concevoir des solutions techniques et participer à la réalisations de celles-ci.</p>
            <p>Vers la fin d'année 2022 début 2023 j'ai commencé à me former plus particulièrement sur les technologies ops tel que <a href="https://www.jenkins.io/" target="_blank" rel="noreferrer">Jenkins</a>, <a href="https://www.ansible.com/" target="_blank" rel="noreferrer">Ansible</a>, <a href="https://kubernetes.io/" target="_blank" rel="noreferrer">Kubernetes</a>, <a href="https://www.rancher.com/" target="_blank" rel="noreferrer">Rancher</a> et <a href="https://www.redhat.com/fr/technologies/cloud-computing/openshift" target="_blank" rel="noreferrer">Openshift</a>.</p>
            <p>Pour moi un bon développeur doit aussi avoir une vision claire des contraintes de déploiement lors de ces développements pour pouvoir livrer un code maintenable et scalable.</p>
          </div>
          <div className="wrapper">
            <h2>2019 - 2021 | Projet de création d'entreprise</h2>
            <p>Pendant mon alternance à Thales avionics, j'ai du travailler sur la création d'un projet de bout à bout avec mon école.</p>
            <p>J'ai trouvé ce projet tellement passionant qu'on a voulu le mettre avec un camarade de classe.</p>
            <p>Nous voulons créer une console de salon virtuelle, on veut pouvoir jouer a des jeux vidéo coopératifs et familiaux sans avoir besoin d'amener sa console partout avec soi, juste un accès internet suffira.</p>
            <p>Dans la finalité, nous avons réussi à développer <a href="https://playdeviant.com/" target="_blank" rel="noreferrer">la console VGC (Virtual Gaming Console)</a> ainsi que son premier jeu, cependant le contexte Covid-19 nous empêche de mettre en oeuvre notre campagne de communication.</p>
            <p>La console est développée en <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a>, <a href="https://reactnative.dev/" target="_blank" rel="noreferrer">React Native</a>, <a href="https://nodejs.dev/" target="_blank" rel="noreferrer">NodeJS</a> et <a href="https://www.mongodb.com/" target="_blank" rel="noreferrer">MongoDB</a>.</p>
            <p>La partie visuelle du jeu est dévellopée avec <a href="https://unity.com/fr" target="_blank" rel="noreferrer">Unity</a> et son back-end avec <a href="https://nodejs.dev/" target="_blank" rel="noreferrer">NodeJS</a></p>
            <p>Nous utilisons <a href="https://git-scm.com/" target="_blank" rel="noreferrer">Git</a> pour partager le code ainsi que gérer les versions de chacune des parties de ce projet, plus particulièrement l'outil <a href="https://gitlab.com/" target="_blank" rel="noreferrer">Gitlab</a> qui nous permet entre autre la mise en ligne automatique du site web grâce au systeme de Pipelines (DevOps).</p>
          </div>
          <div className="wrapper">
            <h2>2018 - 2019 | Développeur web à <a href="https://www.thalesgroup.com/fr/aeronautique" target="_blank" rel="noreferrer">Thales Avionics</a></h2>
            <p>Pendant mon année d'alternance à <a href="https://www.thalesgroup.com/fr/aeronautique" target="_blank" rel="noreferrer">Thales Avionics</a>, j'ai pu travailler sur un projet innovant à destination des compagnies aériennes avec des technologies récentes comme <a href="https://angular.io/" target="_blank" rel="noreferrer">Angular 7</a>, <a href="https://nodejs.dev/" target="_blank" rel="noreferrer">NodeJS</a>, <a href="https://www.mongodb.com/" target="_blank" rel="noreferrer">MongoDB</a> et <a href="https://www.docker.com/" target="_blank" rel="noreferrer">Docker</a>.</p>
            <p>J'ai globalement travaillé seul sur ce projet même si j'ai du intégrer un nouveau collaborateur en milieu de projet.</p>
          </div>
          <div className="wrapper">
            <h2>2016 - 2018 | Développeur web au <a href="https://www.linkedin.com/company/le-soleil-vert---agence-web/about/" target="_blank" rel="noreferrer">Soleil Vert</a></h2>
            <p>J'ai démarré l'alternance dans une petite agence web à Toulouse nommée <a href="https://www.linkedin.com/company/le-soleil-vert---agence-web/about/" target="_blank" rel="noreferrer">Le Soleil Vert</a>.</p>
            <p>Au départ j'étais le seul développeur et salarié de l'agence. Puis grâce aux améliorations de nos methodes de développement (utilisation de <a href="https://git-scm.com/" target="_blank" rel="noreferrer">Git</a>) et de déploiement (Pipelines de <a href="https://gitlab.com/" target="_blank" rel="noreferrer">Gitlab</a>), nous avons grandement augmenté le chiffre d'affaire de l'agence la première année.</p>
            <p>Cette hausse du chiffre d'affaire nous a permis de recruter des nouveaux collaborateurs, j'ai alors du gérer l'équipe du point de vu technique pendant une année.</p>
            <p>J'ai pu apprendre à utiliser les langages PHP, HTML, CSS et JavaScript. J'ai aussi appris à utiliser des outils comme <a href="https://cordova.apache.org/" target="_blank" rel="noreferrer">Cordova</a> pour encapsuler des sites webs en applications mobiles, <a href="https://www.docker.com/" target="_blank" rel="noreferrer">Docker</a> pour gérer mes environements d'execution. Pendant la deuxième année nous avons aussi commencé à utiliser des Frameworks comme <a href="https://fr.reactjs.org/" target="_blank" rel="noreferrer">ReactJS</a> et <a href="https://angular.io/" target="_blank" rel="noreferrer">Angular</a> pour le front-end, et <a href="https://nodejs.dev/" target="_blank" rel="noreferrer">NodeJS</a> pour le back-end.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default ExpPage
