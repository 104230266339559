import React, { Component } from "react"
import { NavLink } from 'react-router-dom'
import './style.scss'

class Tab extends Component {
    render() {
        return (
            <NavLink to={this.props.path || '/'} exact activeClassName="active" className="tab-wrapper">
                {this.props.icon ? <img src={this.props.icon} alt={this.props.name} /> : null}
                <div className="title">{this.props.name}</div>
                <div className="hover-line"></div>
            </NavLink>
        )
    }
}

export default Tab