import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import { ExpPage, InfoPage, FormPage, CompPage, RealPage } from "./pages"
import { Header } from './containers'

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <img src="/images/me_bottom.webp" className="background" alt="leo le pogam" />
        <Switch>
          <Route path="/experiences">
            <ExpPage />
          </Route>
          <Route path="/formations">
            <FormPage />
          </Route>
          <Route path="/competences">
            <CompPage />
          </Route>
          <Route path="/realisations">
            <RealPage />
          </Route>
          <Route>
            <InfoPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App
